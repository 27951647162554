<app-header></app-header>
<!-- <div *ngIf="loadGAD || loadAWWA || loadAKF">
  <div class="loader">
    <div class="loader-spinner"></div>
    <p>Chargement en cours...</p>
  </div>
</div> -->
<div class="nous-connaitre-body" *ngIf="!loadGAD && !loadAWWA && !loadAKF">
  <div class="dev-title">
    <div class="left-section">
      <div class="left-section-body">
        <h1 [innerHTML]="globalHeaderData[0]?.body"></h1>
        <!-- <h4 [innerHTML]="globalHeaderData[0]?.info"></h4> -->
      </div>
    </div>
    <div class="right-section">
      <div class="background-image" [ngStyle]="{ 'background-image': 'url(' + globalHeaderData[0]?.field_image?.uri + ')' } "></div>

      <!-- <img [src]="globalHeaderData[0]?.field_image?.uri" alt="header-image" /> -->
    </div>
  </div>
  <div class="nous-connaitre-container">
    <div class="nous-connaitre-top-section">
      <div class="nous-connaitre-header">
        <div class="body-video">
          <div class="header-video">
            <!-- <div class="header-video" *ngIf="videoLoaded">
              <iframe
                width="100%"
                height="100%"
                [src]="videoUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div> -->
              <div class="img-container" *ngIf="!videoLoaded && imageUrl">
                <img [src]="imageUrl" alt="header-image" />
              </div>
          </div>
        </div>
        <div class="header-text">
          <div class="body-header-text">
            <div class="title-container">
              <h2>{{ globalAboutData[0]?.title }}</h2>
              <div class="underline"></div>
            </div>
            <div class="header-p">
              <p class="header-p1">
                {{ globalAboutData[0]?.sub_title }}
              </p>

              <div class="header-p2">
                  <div
                    class="header-p2-content"
                    [innerHTML]="globalAboutData[0]?.content"
                  ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="chiffres-cles" class="sub-body">
      <div class="sub-body-container">
        <div class="left-side">
          <div class="left-side-title">
            <h2>CHIFFRES CLES</h2>
            <div class="underline"></div>
          </div>
          <!-- <p [innerHTML]="keyFiguresData[0]?.body"></p> -->
        </div>
        <div class="right-side">
          <ng-container *ngFor="let field of fields">
            <div *ngIf="keyFiguresData[0]?.[field + '_titre'] != '' && keyFiguresData[0]?.[field + '_description'] != ''" class="right-side-card">
              <div class="card">
                <div class="card-title">
                  <h3>{{ keyFiguresData[0]?.[field + '_titre'] }}</h3>
                </div>
                <div class="card-subtitle-body">
                  <div class="card-subtitle">
                    <p
                      [innerHTML]="keyFiguresData[0]?.[field + '_description']"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="valeurs-body" *ngIf="showValeurs">
      <div class="valeurs-body-container">
        <div class="valeurs-title-container">
          <h2>NOS VALEURS</h2>
          <div class="underline"></div>
        </div>
        <div class="valeurs-card-container">
          <div class="valeurs-card" *ngFor="let item of valeursData">
            <div class="valeurs-card-header">
              <img [src]="item.image" alt="valeurs image" />
              <h3>{{ item.title }}</h3>
            </div>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-pre-footer></app-pre-footer>
  <app-footer></app-footer>
</div>
