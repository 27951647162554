import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { IndexMenuComponent } from './legacy/index-menu';
import { StructureParticularModule } from './legacy/particular/structure/structure.module';
import { PageAccueilComponent } from './pages/page-accueil/page-accueil.component';
// import { HeaderComponent } from './components/header/header.component';
import { NoContentComponent } from './components/no-content';
import { ActualitesComponent } from './components/actualites/actualites.component';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { GridProductComponent } from './components/grid-product/grid-product.component';
import { HeaderComponent } from './components/header/header.component';
// import { NoContentComponent } from './components/no-content';
import { ContactComponent } from './components/contact/contact.component';
import { SiteMapComponent } from './legacy/particular/accueil/site-map/site-map.component';
import { NousConnaitreComponent } from './components/nous-connaitre/nous-connaitre.component';
// import { SearchResultComponent } from './legacy/search/search-result/search-result.component';
import { DocumentsUtilesComponent } from './components/documents-utiles/documents-utiles.component';
import { NousRejoindreComponent } from './components/nous-rejoindre/nous-rejoindre.component';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { OrganigrammeComponent } from './components/organigramme/organigramme.component';

const routes: Routes = [
  // { path: '', redirectTo: 'particulier', pathMatch: 'full' },
  { path: '', component: PageAccueilComponent },

  { path: 'particulier', component: PageAccueilComponent },
  { path: 'actualites', component: ActualitesComponent },
  { path: 'actualites/:titre/:id', component: ArticleDetailsComponent },
  { path: 'product', component: GridProductComponent },
  { path: 'contact', component: ContactComponent },
  // { path: "header" , component: HeaderComponent },
  // { path: 'help', component: HelpSectionComponent },
  // { path: 'prefooter', component: PreFooterComponent },
  // { path: 'footer', component: FooterComponent },
  // { path: 'actualites', component: ActualitesComponent },
  // { path: 'search-results', component: SearchResultComponent },
  // { path: 'search-results', loadChildren:()=>import('./legacy/search/search.module').then(mod=>mod.SearchModule) },
  // { path: 'product', component: GridProductComponent },
  // { path: 'news-details/:id', component: NewsDetailsComponent },
  {
    path: 'particulier',
    loadChildren: () =>
      import('./legacy/particular/accueil/accueil.module').then(
        (mod) => mod.AccueilModule
      ),
  },
  {
    path: 'particulier/sante',
    loadChildren: () =>
      import('./legacy/particular/health/health.module').then(
        (mod) => mod.HealthModule
      ),
  },
  {
    path: 'particulier/epargne',
    loadChildren: () =>
      import('./legacy/particular/epargne/epargne.module').then(
        (mod) => mod.EpargneModule
      ),
  },
  {
    path: 'particulier/prevoyance',
    loadChildren: () =>
      import('./legacy/particular/prevoyance/prevoyance.module').then(
        (mod) => mod.PrevoyanceModule
      ),
  },
  {
    path: 'particulier/retraite',
    loadChildren: () =>
      import('./legacy/particular/retraite/retraite.module').then(
        (mod) => mod.RetraiteModule
      ),
  },
  {
    path: 'particulier/other',
    loadChildren: () =>
      import('./legacy/particular/other/other.module').then(
        (mod) => mod.OtherModule
      ),
  },
  {
    path: 'entreprise',
    loadChildren: () =>
      import('./legacy/enterprise/enterprise.module').then(
        (mod) => mod.EnterpriseModule
      ),
  },
  {
    path: 'prm',
    loadChildren: () =>
      import('./legacy/prm/prm.module').then((mod) => mod.PrmModule),
  },
  // {path: 'customer-space', loadChildren: './customer-space/customer.module#CustomerModule'},
  // {path: 'intermediate', loadChildren: './intermediate/intermediate.module#IntermediateModule'},
  {
    path: 'recherche',
    loadChildren: () =>
      import('./legacy/search/search.module').then((mod) => mod.SearchModule),
  },
  // { path: 'headerheader', loadChildren:()=>import('./legacy/customer-space/header/header.component').then(mod=>mod.HeaderCustomerSpacetComponent) },
  {
    path: 'espace-client',
    loadChildren: () =>
      import('./download-app/download-app.module').then(
        (mod) => mod.DownloadAppModule
      ),
  },
  { path: 'sommaire', component: IndexMenuComponent },
  { path: 'sitemap', component: SiteMapComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'nous-connaitre/qui-sommes-nous', component: NousConnaitreComponent },
  { path: 'documents-utiles', component: DocumentsUtilesComponent },
  { path: 'nous-connaitre/nous-rejoindre', component: NousRejoindreComponent },
  { path: 'mention', component: LegalNoticeComponent },
  { path: 'confidentialite', component: LegalNoticeComponent },
  { path: 'cgu', component: LegalNoticeComponent },
  { path: 'nous-connaitre/organigramme', component: OrganigrammeComponent },
  { path: '**', component: NoContentComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: NoPreloading,
      scrollPositionRestoration: 'top',
    }),
  ],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
