// import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { RouterModule, Routes } from '@angular/router';
// import { provideHttpClient } from '@angular/common/http';

// import { NoContentComponent } from './no-content';
// import { AppRoutingModule } from './app-routing.module';
// import { AppComponent } from './app.component';

// @NgModule({
//   declarations: [
//     AppComponent,
//     NoContentComponent
//   ],
//   imports: [
//     BrowserModule,
//     AppRoutingModule,
//     BrowserAnimationsModule,
//     RouterModule
//   ],
//   providers: [
//     provideHttpClient()
//   ],
//   bootstrap: [AppComponent],

// })
// export class AppModule { }

import { LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors} from '@angular/common/http';
import { RouterModule, NoPreloading } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NoContentComponent } from './components/no-content';
import { HttpClientModule } from '@angular/common/http';
import {enableProdMode} from '@angular/core';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState } from './app.service';

import { IndexMenuComponent } from './legacy/index-menu';
// import { LocalStorageModule } from '@ngx-pwa/local-storage';

// import { StructureParticularModule } from './legacy/particular/structure/structure.module';
import { GlobalService } from './legacy/providers/global.service';
import { AuthenticationService} from './legacy/providers/authentication.services';
import { TokenInterceptor} from './legacy/providers/interceptor.services';
import { registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ToastModule } from 'ng2-toastr';
import { HelpSectionComponent } from './components/help-section/help-section.component';
import { PreFooterComponent } from './components/pre-footer/pre-footer.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from './loading.interceptor';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState,
  GlobalService,
  AuthenticationService
];

registerLocaleData(localeFr, 'fr');
enableProdMode();
/**
 * `AppModule` is the main entry point into Angular2's bootstrapping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    IndexMenuComponent,
    SpinnerComponent
    // NoContentComponent,
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // HttpClientModule,
    // LocalStorageModule,
    // AnimateOnScrollModule.forRoot(),
    AppRoutingModule,
    RouterModule,
    MatSidenavModule,
    HttpClientModule
    // StructureParticularModule
    // HeaderComponent,
    // PreFooterComponent,
    // HelpSectionComponent,
    // FooterComponent
    // ToastModule.forRoot()
  ],
  providers: [
    provideHttpClient(withInterceptors([TokenInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    environment.ENV_PROVIDERS,
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    APP_PROVIDERS
  ],

  exports: [
    // StructureParticularModule
  ],
})
export class AppModule {}
