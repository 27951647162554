<app-header></app-header>
<div class="container-acceuil" *ngIf="!isLoading">
  <div class="container-acceuil-body">
    <div id="carouselExampleIndicators" class="carousel  slide mb-2" *ngIf="sliderItems.length">
      <div class="carousel-indicators">
        <button *ngFor="let item of sliderItems; let i = index" type="button"
          [attr.data-bs-target]="'#carouselExampleIndicators'" [attr.data-bs-slide-to]="i" [class.active]="i === 0"
          [attr.aria-label]="'Slide ' + (i + 1)"></button>
      </div>
      <div class="carousel-inner">
        <a *ngFor="let item of sliderItems; let i = index" [href]="item.field_lien.uri"
          class="carousel-item carousel-item-custom "
          [ngStyle]="{'background-image': 'url(' + item.field_image.uri + ')'}" [class.active]="i === 0"
          [class.video-slide]="item.field_youtube_url_popup" target="_blank">
          <!-- <img [src]="item.field_image.uri" [alt]="'Header Image ' + (i + 1)" /> -->
          <div *ngIf="!item.field_youtube_url_popup?.length" class="header-text" style="right: 9%; top: 60%;">
            <p>{{ item.field_titre }}</p>
            <a class="btn rounded-pill" style="background-color: rgba(230, 0, 40, 1);color: white;">En savoir plus</a>
          </div>
          <div *ngIf="item.field_youtube_url_popup?.length" class="header-text video-text"
            style="font-size: 28px; font-weight: 700; width: 50%;">
            <h2 class="mb-5 text-start lh-sm">{{ item.field_titre }}</h2>
            <button (click)="playVideo(item.field_youtube_url_popup) ; $event.preventDefault();"
              class="btn-play rounded-pill">
              <img src="assets/images/Group65859.svg">
            </button>
          </div>
        </a>
      </div>

      <button class="carousel-control-prev d-none d-md-block" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev" style="left: 4%;">
        <img src="/assets/images/Vector (5).svg" alt="Previous" class="carousel-control-icon" />
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next d-none d-md-block" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next" style="right: 4%;">
        <img src="/assets/images/Vector (4).svg" alt="Next" class="carousel-control-icon" />
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="my-2"></div>

    <!-- Card Grid Section -->
    <div class="row row-gap">
      <!-- Static Blue Card -->
      <div class="col-md-6 col-sm-12  mb-4  static-blue-card-wrapper">
        <a href="/particulier/diagnostic" class="card-link-wrapper">
          <div class="card h-100 shadow  static-blue-card ">
            <div class="icon-container">
              <img src="/assets/images/Group.svg" alt="Icon" />
            </div>
            <div class="card-body">
              <a href="/particulier/diagnostic" class="card-link">
                <div class="card-text-wrapper">
                  <p class="card-text">Calculer votre niveau de protection</p>
                  <!-- <span class="arrow">&rarr;</span> -->
                </div>
              </a>
            </div>
          </div>
        </a>
      </div>

      <!-- Dynamic Cards -->
      <div class="col-md-3 col-sm-6 mb-4 " *ngFor="let item of items">
        <a [href]="item.slug" class="card-link-wrapper">
          <div class="card h-100 text-center shadow position-relative">
            <img [src]="item.img_grid" class="card-img-top" alt="Item Image" />
            <div class="card-body">
              <div class="card-text-wrapper">
                <p class="card-text"><a class="text-a" [href]="item.slug">{{ item.title }}</a></p>
                <!-- <span class="arrow">&rarr;</span> -->
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- Video Modal -->
    <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="videoModalLabel">Video</h5> -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              (click)="stopVideo()"></button>
          </div>
          <div class="modal-body">
            <iframe id="modalVideo" width="1366px" height="500" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              src="https://www.youtube.com/embed/VIDEO_ID?enablejsapi=1" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
<div class="app-help-section-container">
  <div class="help-section">
    <app-help-section></app-help-section>
  </div>
</div>
<app-pre-footer></app-pre-footer>
<app-footer></app-footer>
