import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { HelpSectionComponent } from '../help-section/help-section.component';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { FooterComponent } from '../footer/footer.component';
import { GlobalService } from '../../legacy/providers/global.service';
import { FormsModule } from '@angular/forms';

interface Document {
  category: string;
  desc: string | null;
  file: string;
  name: string;
  nid: string;
  updated: string;
}

interface CategoryGroup {
  category: string;
  documents: Document[];
}

@Component({
  selector: 'app-documents-utiles',
  standalone: true,
  imports: [
    HeaderComponent,
    HelpSectionComponent,
    PreFooterComponent,
    FooterComponent,
    CommonModule,
    FormsModule
  ],
  templateUrl: './documents-utiles.component.html',
  styleUrl: './documents-utiles.component.css'
})
export class DocumentsUtilesComponent {
  constructor(private globalService: GlobalService) {}

  organizedDocuments: CategoryGroup[] = [];
  searchKey: string;
  documents: Document[] = [];
  options: string[] = [];
  isLoading = false;


  ngOnInit() {
    this.loadGlobalDocuments();
  }

  loadGlobalDocuments() {
    this.isLoading = true; 
    this.globalService.getDocuments().subscribe((documents: any) => {
      this.documents = documents.documents;
      this.options = documents.options;
      this.organizedDocuments = this.organizeDocumentsByCategory(documents.documents, documents.options);
      console.log('documents:', this.documents);
      this.isLoading= false; 
    });
  }

// organizeDocumentsByCategory -------------------------------------------------------------------------------------------
  organizeDocumentsByCategory(documents: Document[], categories: string[]): CategoryGroup[] {
    if (!Array.isArray(documents) || !Array.isArray(categories)) {
      console.error('Invalid input: documents and categories must be arrays');
      return [];
    }

    const organizedDocs = categories.reduce<Record<string, Document[]>>((acc, category) => {
      acc[category] = [];
      return acc;
    }, {});

    documents.forEach(doc => {
      if (doc && doc.category && doc.file && organizedDocs.hasOwnProperty(doc.category)) {
        organizedDocs[doc.category].push(doc);
      }
    });

    Object.keys(organizedDocs).forEach(category => {
      if (organizedDocs[category].length === 0) {
        delete organizedDocs[category];
      }
    }
    );

    return Object.entries(organizedDocs).map(([category, docs]) => ({
      category,
      documents: docs
    }));
  }


// downloadDocument -----------------------------------------------------------------------------------------------------
  async downloadDocument(downloadLink: string, fileName?: string) {
    try {
      console.log('Downloading document from: ', downloadLink);

      const response = await fetch(downloadLink);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;

      // Set the download attribute with the file name if provided
      link.download = fileName || this.getFilenameFromResponse(response) || 'download';

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to start the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }

  getFilenameFromResponse(response: Response): string | null {
    const contentDisposition = response.headers.get('content-disposition');
    if (!contentDisposition) {
      return null;
    }
    const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
    return filenameMatch ? filenameMatch[1] : null;
  }

  searchDocuments() {
    if (!this.searchKey) {
      this.loadGlobalDocuments();
      return;
    }

    const searchTerm = this.searchKey.toLowerCase();

    const filteredDocuments = this.documents.filter(doc => {
      return (
        (doc.category && doc.category.toLowerCase().includes(searchTerm)) ||
        (doc.name && doc.name.toLowerCase().includes(searchTerm)) ||
        (doc.desc && doc.desc.toLowerCase().includes(searchTerm)) ||
        (doc.file && doc.file.toLowerCase().includes(searchTerm)) ||
        (doc.nid && doc.nid.toLowerCase().includes(searchTerm)) ||
        (doc.updated && doc.updated.toLowerCase().includes(searchTerm))
      );
    });

    this.organizedDocuments = this.organizeDocumentsByCategory(filteredDocuments, this.options);
  }

}


