import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-objet-pop-in',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './objet-pop-in.component.html',
  styleUrl: './objet-pop-in.component.css'
})
export class ObjetPopInComponent {
  @Input() isOpen = false;
  @Input() message = '';
  @Output() closeModal = new EventEmitter<void>();

  close() {
    this.closeModal.emit();
  }

}
