import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpSentEvent, HttpEvent, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.services';

export const TokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let userReq = request;
  console.log("The interceptor is called");
  let token = '';
  if (localStorage.getItem('token')) {
    token = localStorage.getItem('token') ?? '{}';
  }

  if (token != undefined && token != null && token != '' && token != '{}') {
    // console.log("Voici le token ", token);
    userReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    });
  }
  return next(userReq);
};

// @Injectable()
// export class TokenInterceptor implements HttpInterceptor {

//   constructor(private injector: Injector) {
//   }

//   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
//         // add auth header with jwt if user is logged in and request is to the api url
//         const auth = this.injector.get(AuthenticationService);
//         const authToken = auth.getToken();
//         console.log("Token being checked");
//         if (authToken != "undefined") {
//           console.log("Token trouvé: ", authToken);
//           request = request.clone({
//             setHeaders: {
//               Authorization: `Bearer ${authToken}`
//             }
//           });
//         }

//         return next.handle(request);
//     // return next.handle(request).pipe(catchError(error => {
//     //   const auth = this.injector.get(AuthenticationService);
//     //   const authToken = auth.getToken();
//     //   if (error instanceof HttpErrorResponse) {
//     //     switch ((error as HttpErrorResponse).status) {
//     //       case 401:
//     //         auth.logout(); break;
//     //       case 400:
//     //         console.log(error.message); break;
//     //     }
//     //   } else {
//     //     return throwError(() => error);
//     //   }

//     //   const err = error.error?.message || error.statusText;
//     //   console.error(err);
//     //   return throwError(() => error);
//     // }))
//   }

//   private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
//     return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
//   }

// }
