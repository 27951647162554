<div class="index-menu-box">
  <div class="icon">
    <div class="image"> <img src="/assets/img/logo.png"></div>
    <div class="info">
      <div class="bloc-ol">
        <ol>
          <li><a routerLink="/particulier">Home</a></li>
          <li><a routerLink="/particular/need-detail/' + randomNeed]">Détail besoin</a></li>
          <li><a routerLink="/about">Nous connaitre</a></li>
          <li><a routerLink="/particular/diagnostic">Diagnostic</a></li>
          <li><a routerLink="/particular/health">Sante</a></li>
          <li><a routerLink="/particular/retraite">Retraite</a></li>
          <li><a routerLink="/particular/epargne">Épargne</a></li>
          <li><a routerLink="/particular/prevoyance">Prévoyance</a></li>
          <li><a routerLink="/particular/health/dynamic">Dynamic santé</a></li>
          <li><a routerLink="/particular/retraite/dynamic">Dynamic retraite</a></li>
          <li><a routerLink="/particular/epargne/dynamic">Dynamic epargne</a></li>
          <li><a routerLink="/particular/prevoyance/dynamic">Dynamic prevoyance</a></li>
          <li><a routerLink="/particular/health/product/1">Produit santé</a></li>
          <li><a routerLink="/particular/retraite/product/1">Produit retraite</a></li>
          <li><a routerLink="/particular/epargne/product/0">Produit epargne</a></li>
          <li><a routerLink="/particular/prevoyance/product/0">Produit prevoyance</a></li>
          <li><a routerLink="/particular/health/product/1/result">Resultas santé</a></li>
          <li><a routerLink="/particular/retraite/product/1/result">Resultas retraite</a></li>
          <li><a routerLink="/particular/epargne/product/0/result">Resultas epargne</a></li>
          <li><a routerLink="/particular/epargne/product/1/result">Resultas Vital education</a></li>
          <li><a routerLink="/particular/prevoyance/product/0">Wikaya</a></li>
          <li><a routerLink="/particular/prevoyance/product/wikaya/result">Resultat wikaya</a></li>
          <li><a routerLink="/particular/other/souscription">souscription</a></li>
          <li><a routerLink="/prm">PRM Authentification</a></li>
          <li><a routerLink="/prm/member-space">PRM Espace membre</a></li>
        </ol>
      </div>
      <div class="bloc-ol">
        <h2>Vérsion Arabe</h2>
        <ol>
          <li><a routerLink="/particular/home/ar">Home</a></li>
          <li><a routerLink="/particular/health/ar">Sante</a></li>
          <li><a routerLink="/particular/health/dynamic/ar">Dynamic</a></li>
          <li><a routerLink="/particular/diagnostic/ar">Diagnostic</a></li>
          <li><a routerLink="/particular/other/simulator/ar">simulateur global</a></li>
          <li><a routerLink="/about/ar">Nous_connaitre</a></li>
          <li><a routerLink="/particular/other/souscription">Souscription</a></li>
        </ol>
      </div>
      <div class="bloc-ol">
        <h2>Espace client</h2>
        <ol>
          <li><a routerLink="/customer-space/login">Authentification</a></li>
          <li><a routerLink="/customer-space/id-request">Demande d'identifiant</a></li>
          <li><a routerLink="/customer-space/recover-pass">Récupérer mon mot de passee</a></li>
          <li><a routerLink="/customer-space/pass-received">Saisissez le mot de passe reçu</a></li>
          <li><a routerLink="/customer-space/grab-pass">Saisissez le motde passe reçu</a></li>
          <li><a routerLink="/customer-space/edit-pass">Modifier mot de passe</a></li>
          <li><a routerLink="/customer-space/my-contracts">Mes contrats</a></li>
          <li><a routerLink="/customer-space/step-contract">Sehha Essentiel</a></li>
        </ol>
      </div>
      <div class="bloc-ol">
        <h2>Intermédiaire</h2>
        <ol>
          <li><a routerLink="/intermediate/login">Authentification</a></li>
          <li><a routerLink="/intermediate/edit-pass">Modifier le mot de passe</a></li>
          <li><a routerLink="/intermediate/forgot-pass">Mot de passe oublié</a></li>
          <li><a routerLink="/intermediate/product">Page produits</a></li>
          <li><a routerLink="/intermediate/contact-list">Liste des contacts</a></li>
        </ol>
      </div>
    </div>
  </div>
</div>