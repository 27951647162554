<app-header></app-header>
<div class="nous-rejoindre-body" *ngIf="!isLoading">
  <div class="dev-title">
    <div class="left-section">
      <div class="left-section-body">
        <h1 [innerHTML]="globalHeaderData[0]?.body">nous-rejoindre</h1>
        <!-- <h4 [innerHTML]="globalHeaderData[0]?.info">nous-rejoindre-body</h4> -->
      </div>
    </div>
    <div class="right-section">
      <!-- <img [src]="globalHeaderData[0]?.field_image?.uri" alt="header image" /> -->
         <div class="background-image" [ngStyle]="{ 'background-image': 'url(' + globalHeaderData[0]?.field_image?.uri + ')' } "></div>

    </div>
  </div>
  <div class="nous-rejoindre-container">
    <div class="title-container">
      <h2>Formulaire de candidature</h2>
      <div class="underline"></div>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group ">
        <div class="form-sub-group">
          <label class="form-sub-group-label" for="name">Nom *</label>
          <input id="name" formControlName="name" placeholder="Votre nom" />
          <div *ngIf="form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)"
            class="error-message">
            <span *ngIf="form.get('name')?.errors?.['required']">Le nom est requis. </span>
            <span *ngIf="form.get('name')?.errors?.['minlength']">Le nom doit contenir au moins 3 caractères. </span>
            <span *ngIf="form.get('name')?.errors?.['maxlength']">Le nom doit contenir au plus 20 caractères. </span>
          </div>
        </div>
        <div class="form-sub-group">
          <label class="form-sub-group-label" for="lastName">Prénom *</label>
          <input id="lastName" formControlName="lastName" placeholder="Votre prénom" />
          <div *ngIf="form.get('lastName')?.invalid && (form.get('lastName')?.dirty || form.get('lastName')?.touched)"
            class="error-message">
            <span *ngIf="form.get('lastName')?.errors?.['required']">Le prénom est requis. </span>
            <span *ngIf="form.get('lastName')?.errors?.['minlength']">Le prénom doit contenir au moins 3
              caractères. </span>
            <span *ngIf="form.get('lastName')?.errors?.['maxlength']">Le prénom doit contenir au plus 20
              caractères. </span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-sub-group">
          <label class="form-sub-group-label" for="phone">Numéro de téléphone *</label>
          <input id="phone" formControlName="phone" placeholder="Votre numéro de téléphone" />
          <div *ngIf="form.get('phone')?.invalid && (form.get('phone')?.dirty || form.get('phone')?.touched)"
            class="error-message">
            <span *ngIf="form.get('phone')?.errors?.['required']">Le numéro de téléphone est requis.</span>
            <span *ngIf="form.get('phone')?.errors?.['pattern']">Le numéro de téléphone doit être composé de 10
              chiffres. </span>
            <span *ngIf="form.get('phone')?.errors?.['minlength']">Le numéro de téléphone doit contenir au moins 10
              chiffres. </span>
            <span *ngIf="form.get('phone')?.errors?.['maxlength']">Le numéro de téléphone doit contenir au plus 10
              chiffres. </span>
          </div>
        </div>
        <div class="form-sub-group">
          <label class="form-sub-group-label" for="email">Adresse email *</label>
          <input id="email" formControlName="email" placeholder="Votre adresse email" />
          <div *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)"
            class="error-message">
            <span *ngIf="form.get('email')?.errors?.['required']">L'adresse email est requise. </span>
            <span *ngIf="form.get('email')?.errors?.['email']">L'adresse email doit être valide. </span>
          </div>
        </div>
      </div>



      <div class="form-group">
        <div class="form-sub-group">
          <label for="attachment">
            <div class="upload-button-label">
              Pièce jointe *
            </div>
            <div class="upload-button Pj-upload-button">
              <span *ngIf="file; else noFile">{{ file.name }}</span>
              <ng-template #noFile>
                <span class="attachment-text">Ajouter une pièce jointe </span>
              </ng-template>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1780_1816)">
                  <path
                    d="M9.17316 2.96895L9.16732 15.8339C9.16732 16.055 9.25512 16.2669 9.4114 16.4232C9.56768 16.5795 9.77964 16.6673 10.0007 16.6673C10.2217 16.6673 10.4336 16.5795 10.5899 16.4232C10.7462 16.2669 10.834 16.055 10.834 15.8339L10.8398 2.98311L13.2665 5.41061C13.4228 5.56684 13.6347 5.6546 13.8557 5.6546C14.0766 5.6546 14.2885 5.56684 14.4448 5.41061C14.601 5.25434 14.6888 5.04242 14.6888 4.82145C14.6888 4.60048 14.601 4.38855 14.4448 4.23228L11.769 1.55311C11.5368 1.3208 11.2612 1.1365 10.9577 1.01077C10.6543 0.88503 10.3291 0.820312 10.0007 0.820312C9.67222 0.820312 9.34699 0.88503 9.04358 1.01077C8.74016 1.1365 8.46449 1.3208 8.23232 1.55311L5.55649 4.22978C5.40026 4.38605 5.3125 4.59798 5.3125 4.81895C5.3125 5.03992 5.40026 5.25184 5.55649 5.40811C5.71276 5.56434 5.92469 5.6521 6.14566 5.6521C6.36663 5.6521 6.57855 5.56434 6.73482 5.40811L9.17316 2.96895Z"
                    fill="black" />
                  <path
                    d="M18.3333 14.9857V18.319C18.3333 18.54 18.2455 18.752 18.0893 18.9083C17.933 19.0645 17.721 19.1523 17.5 19.1523H2.5C2.27899 19.1523 2.06702 19.0645 1.91074 18.9083C1.75446 18.752 1.66667 18.54 1.66667 18.319V14.9857C1.66667 14.7647 1.57887 14.5527 1.42259 14.3964C1.26631 14.2401 1.05435 14.1523 0.833333 14.1523C0.61232 14.1523 0.400358 14.2401 0.244078 14.3964C0.0877974 14.5527 0 14.7647 0 14.9857L0 18.319C0 18.982 0.263392 19.6179 0.732233 20.0868C1.20107 20.5556 1.83696 20.819 2.5 20.819H17.5C18.163 20.819 18.7989 20.5556 19.2678 20.0868C19.7366 19.6179 20 18.982 20 18.319V14.9857C20 14.7647 19.9122 14.5527 19.7559 14.3964C19.5996 14.2401 19.3877 14.1523 19.1667 14.1523C18.9457 14.1523 18.7337 14.2401 18.5774 14.3964C18.4211 14.5527 18.3333 14.7647 18.3333 14.9857Z"
                    fill="black" />
                </g>
                <defs>
                  <clipPath id="clip0_1780_1816">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.820312)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </label>
          <input type="file" id="attachment" (change)="onFileSelected($event)" style="display:none;">
          <div
            *ngIf="form.get('attachment')?.invalid && (form.get('attachment')?.dirty || form.get('attachment')?.touched)"
            class="error-message">
            <span *ngIf="form.get('attachment')?.errors?.['required']">La pièce jointe est requise. </span>
            <span class="error-message" *ngIf="attachmentError">{{ attachmentError }}</span>
          </div>
        </div>
      </div>

      <div class="dropdown">
        <label class="form-sub-group-label" for="message">Message * </label>
        <textarea formControlName="message" placeholder="Votre message"></textarea>
        <div *ngIf="form.get('message')?.invalid && (form.get('message')?.dirty || form.get('message')?.touched)"
          class="error-message">
          <span *ngIf="form.get('message')?.errors?.['required']">Le message est requis. </span>
          <span *ngIf="form.get('message')?.errors?.['minlength']">Le message doit contenir au moins 10
            caractères. </span>
          <span *ngIf="form.get('message')?.errors?.['maxlength']">Le message doit contenir au plus 200
            caractères. </span>
        </div>
      </div>
      <div class="checkbox-container">
        <input class="styled-checkbox" formControlName="checkCondition" id="styled-checkbox-2" type="checkbox"
          #checkCondition>
        <div class="text">
          J'ai lu et j'accepte <a href="/mention" class="mentions-legal">les mentions légales et les conditions
            générales
            d'utilisation,</a> notamment l'utilisation de mes données personnelles à des fins
          de prospection.
        </div>
      </div>


      <button class="submit-button" type="submit" [disabled]="form.invalid">Envoyer</button>
    </form>
  </div>

  <div class="sub-body">
    <div class="sub-body-top-container">
      <div class="sub-body-top">
        <div class="subBody-card" *ngFor="let item of subBodyData">
          <div class="subBody-card-image">
            <img [src]="item.icon" alt="Image" />
          </div>
          <div class="subBody-card-content">
            <div class="title">
              <h3>{{ item.title }}</h3>
            </div>
            <p [innerHTML]="item.description"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-body-botom-container">
      <div class="backgrond-image">
        <img [src]="CondidatureBlockData[0]?.field_image?.uri" alt="Image" />
      </div>
      <div class="main-content">
        <div class="sub-body">
          <div class="title">
            <h3 [innerHTML]="CondidatureBlockData[0]?.body"></h3>
          </div>
          <button class="email-button" (click)="onEmailClick(CondidatureBlockData[0]?.field_email)">{{ CondidatureBlockData[0]?.field_email }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-pre-footer></app-pre-footer>
<app-footer></app-footer>
