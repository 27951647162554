<app-header></app-header>
<div class="organigramme-body" *ngIf="!isLoading">

  <div class="dev-title">
    <div class="left-section">
      <div class="left-section-body">
        <h1 [innerHTML]="organirgammeHeader[0]?.body"></h1>
        <!-- <h4 [innerHTML]="organirgammeHeader[0]?.info"></h4> -->
      </div>
    </div>
    <div class="right-section">
      <div class="background-image" [ngStyle]="{ 'background-image': 'url(' + organirgammeHeader[0]?.field_image?.uri + ')' } "></div>
      <!-- <img [src]="organirgammeHeader[0]?.field_image?.uri" alt="header-image" /> -->
    </div>
  </div>

  <div class="organigramme-container">
    <div class="organigramme-section">
      <div class="document-card" *ngFor="let item of organirgammeData">
        <div class="avatar" style="background-image: url({{item.image}})">
          <!-- <img [src]="item.image" alt="avatar" /> -->
        </div>
        <div class="organigramme-card-content">
          <h3>{{ item.title }}</h3>
          <p [innerHTML]="item.description"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-pre-footer></app-pre-footer>
<app-footer></app-footer>
