import { Component, ViewEncapsulation } from '@angular/core';

@Component ({
  selector: 'index-menu',
  styleUrls: [ './index-menu.component.css' ],
  templateUrl: './index-menu.component.html',
  encapsulation: ViewEncapsulation.None
})

export class IndexMenuComponent {

  public randomNeed = Math.floor(Math.random() * 9) + 1;

}
