import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Need } from '../../models/need';
import { environment } from '../../../environments/environment';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  // export const BASE_URL: string = 'https://ws.lmv.thenextclick.net/';
@Injectable({
  providedIn: 'root'
})
export class DataService {
private prefix: string = environment.BASE_URL + '/global/';
private httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

  constructor(private http: HttpClient) { }

//   getData(): Observable<any> {
//     return this.http.get('http://ws.lmvapid9.win/');
//   }
  public getNeedsByType(type: string) : Observable<Need[]>{
    // return this.http.get<Need[]>(this.prefix + 'needs?type_need=' + type);
    const url = `${this.prefix}needs?type_need=${encodeURIComponent(type)}`;
    return this.http.get<Need[]>(url, this.httpOptions);
  }
}
