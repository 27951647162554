<app-header></app-header>
<div class="document-utiles-body" *ngIf="!isLoading">
  <div class="dev-title">
    <div class="left-section">
      <div class="left-section-body">
        <h1>Documents utiles</h1>
        <!-- <br />
        <h4>Loream ipsum dolor</h4> -->
      </div>
    </div>
    <div class="right-section">
      <img src="assets\images\documents-utiles.png" alt="header image" />
    </div>
  </div>
  <div class="Rechercher-documents-container">
    <div class="Rechercher-documents">
      <input type="text" placeholder="Rechercher des documents" [(ngModel)]="searchKey" aria-label="Search documents"
        (keyup.enter)="searchDocuments()" />
      <button class="btn" (click)="searchDocuments()">
        <div class="search-icon-circle">
          <img src="assets\images\search-icon.svg" alt="Search Icon" />
        </div>
      </button>
    </div>
  </div>
  <div class="document-utiles-container">
    <div class="document-utiles-card" *ngFor="let item of organizedDocuments">
      <div class="document-utiles-card-header">
        <h4>{{ item.category }}</h4>
      </div>
      <div class="document-card-container">
        <div class="document-card" *ngFor="let doc of item.documents">
          <div class="divider-dev"></div>
          <div class="document-card-body">
            <div class="document-left-side">
              <img src="assets\images\document-icon.svg" alt="documents Icon" />
              <p>{{ doc.name }}</p>
            </div>
            <button class="btn btn-primary" (click)="downloadDocument(doc.file, doc.name)">
              <p>Télécharger</p>
              <img src="assets\images\download-icon.svg" alt="Download Icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-help-section></app-help-section>
  </div>
</div>

<app-pre-footer></app-pre-footer>
<app-footer></app-footer>
