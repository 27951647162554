<app-header></app-header>
<div class="legal-notic-body" *ngIf="!isLoading">
  <div class="dev-title">
    <div class="left-section">
      <div class="left-section-body">
         <div  [innerHTML]="legalNoticeHeader[0]?.body">
        </div>
      </div>
    </div>
    <div class="right-section">
      <img [src]="legalNoticeHeader[0]?.field_image?.uri" alt="header-image" />
    </div>
  </div>
  <div class="legal-notice-container">
    <div class="legal-notice-card" *ngFor="let item of legalsData;let i = index">
      <div class="card-header">
        <div class="legal-notice-card-header">
          <h4>{{ item.title }}</h4>
          <div class="underline"></div>
        </div>
        <a *ngIf="isMobileView" (click)="toggleDropCard(i)">
          <img *ngIf="!openCards[i]" src="/assets/images/drop-icon.svg" alt="drop-image" />
          <img *ngIf="openCards[i]" src="/assets/images/down-icon.svg" alt="down-image" />
        </a>
      </div>
      <div *ngIf="openCards[i] || !isMobileView" class="legal-notice-card-body">
        <div [innerHTML]="item.content"></div>
      </div>
    </div>
  </div>
  <div class="app-help-section-container">
    <div class="help-section">
      <app-help-section></app-help-section>
    </div>
  </div>
</div>
<app-pre-footer></app-pre-footer>
<app-footer></app-footer>
