import { Component, OnInit, Renderer2, Output, Input, EventEmitter, AfterViewInit, ElementRef, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../legacy/providers/global.service';
import { Article } from '../../models/article.model';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { PreFooterComponent } from "../pre-footer/pre-footer.component";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


declare var bootstrap: any;
interface ParsedElement {
  type: string;
  data: string;
  level?: number;
  text?: string;
  src?: string;
  alt?: string;
  width?: string;
  height?: string;
  sources?: Array<{ src: string; type: string }>;
  controls?: boolean;
  isIframe?: boolean;
  href?: string;
}
@Component({
  selector: 'app-article-details',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent, PreFooterComponent],
  templateUrl: './article-details.component.html',
  styleUrl: './article-details.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ArticleDetailsComponent implements OnInit {
  article: Article | undefined;
  readAlso: Article[] = [];
  news: any;
  encodedUrl: string = '';
  bodyTransformed: string = '';
  videoLink: string = '';
  @ViewChild('newsContent', { static: false }) newsContent: ElementRef;
  @ViewChild('modal', { static: false }) modalWindow: ElementRef;
  currentUrl: string = 'https://example.com';
  @Input() videoType: 'video' | 'iframe' = 'video';
  isOpen: boolean = false;
  sanitizedUrl: SafeResourceUrl = '';
  newsElements: any[] = [];
  isLoading = false;


  private modalElement: HTMLElement;
  private videoElement: HTMLVideoElement;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private renderer: Renderer2  ) { }

  ngOnInit(): void {
    const idParam = this.route.snapshot.paramMap.get('id');
    if (idParam !== null) {
      const id = +idParam;
      this.loadNewsDetails(id);
    } else {
      console.error('No ID provided in route');
    }
    // this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

  ngAfterViewInit(): void {
    if (this.newsContent.nativeElement.querySelector('p.popup-video')) {
      this.modalWindow.nativeElement.querySelector('.btn-close').addEventListener('click', (event:any) => {
        event.preventDefault();
        this.modalWindow.nativeElement.style.display = 'none';
      });
      const imageLinks = this.newsContent.nativeElement.querySelectorAll('p.popup-video a');
      imageLinks.forEach((imgLink: any) => {
        imgLink.addEventListener('click', (event: any) => {
          this.popupVideoDisableClick(event, imgLink.getAttribute('href'));
        })
      });
    }
  }

  loadNewsDetails(id: number): void {
    this.isLoading= true;
    this.globalService.getArticleDetails(id).subscribe(response => {
      error: (err: any) => {
        console.error("Unable to get the article info", err);
        this.isLoading= false;
      }
      success: {
        this.isLoading= false;
        this.news = response.data.data;
        this.readAlso = response.data.readAlso;
        this.currentUrl = window.location.href;
        this.transformImagePaths(this.news.body);
      }
    });
  }

  // updateMetaTags(): void {
  //   this.updateOrCreateMetaTag('og:title', this.news.title);
  //   this.updateOrCreateMetaTag('og:description', this.news.description);
  //   this.updateOrCreateMetaTag('og:image', this.news.image);
  //   this.updateOrCreateMetaTag('og:url', this.currentUrl);
  //   this.updateOrCreateMetaTag('og:type', 'article');
  // }

  updateOrCreateMetaTag(property: string, content: string): void {
    let metaTag = this.renderer.selectRootElement(`meta[property="${property}"]`, true);

    if (metaTag) {
      this.renderer.setAttribute(metaTag, 'content', content);
    } else {
      metaTag = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaTag, 'property', property);
      this.renderer.setAttribute(metaTag, 'content', content);
      this.renderer.appendChild(document.head, metaTag);
    }
  }


  transformImagePaths(htmlContent: string): void {
    // return htmlContent.replace(/src="\/sites\/default\/files\/news-images\/(.+?)"/g, 'src="https://ws.lmv.thenextclick.net/sites/default/files/news-images/$1"');
    this.bodyTransformed = htmlContent.replace(/src="\/sites\/default\/files\/news-images\/(.+?)"/g, 'src="https://ws.lmv.thenextclick.net/sites/default/files/news-images/$1"');
  }

  popupVideoDisableClick(event: any, link: any): void {
    console.log(event);
    event.preventDefault();
    this.openModal(link);
  }

  parseHtmlBody(htmlBody: string): ParsedElement[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlBody, 'text/html');
    const elements = doc.body.children;

    const parsedElements: ParsedElement[] = [];

    function parseElement(element: Element): ParsedElement | ParsedElement[] {
      const elementType = element.tagName.toLowerCase();

      if (elementType === 'p') {
        const children = Array.from(element.children);
        if (children.length > 0) {
          const textNodes = Array.from(element.childNodes).filter(node => node.nodeType === Node.TEXT_NODE);
          const textContent = textNodes.map(node => node.textContent?.trim()).filter(Boolean).join(' ');

          if (textContent) {
            parsedElements.push({
              type: 'p',
              data: textContent,
              text: textContent
            });
          }

          return children.flatMap(child => parseElement(child));
        }
      }

      const obj: ParsedElement = {
        type: elementType === 'iframe' ? 'video' : elementType,
        data: element.outerHTML,
      };

      switch (elementType) {
        case 'h3':
          obj.level = 3;
          obj.text = element.textContent || '';
          break;
        case 'p':
          obj.text = element.textContent || '';
          break;
        case 'img':
          obj.src = element.getAttribute('src') || '';
          obj.alt = element.getAttribute('alt') || '';
          const parentAnchor = element.closest('a');
          if (parentAnchor) {
            obj.href = parentAnchor.getAttribute('href') || '';
          }
          break;
        case 'iframe':
          obj.isIframe = true;
          obj.src = element.getAttribute('src') || '';
          obj.width = element.getAttribute('width') || '';
          obj.height = element.getAttribute('height') || '';
          break;
        case 'video':
          obj.controls = element.hasAttribute('controls');
          obj.width = element.getAttribute('width') || '';
          obj.height = element.getAttribute('height') || '';
          obj.sources = Array.from(element.getElementsByTagName('source')).map(source => ({
            src: source.getAttribute('src') || '',
            type: source.getAttribute('type') || ''
          }));
          break;
        case 'a':
          obj.href = element.getAttribute('href') || '';
          break;
      }

      return obj;
    }
    Array.from(elements).forEach(element => {
      const parsed = parseElement(element);
      if (Array.isArray(parsed)) {
        parsedElements.push(...parsed);
      } else {
        parsedElements.push(parsed);
      }
    });

    return parsedElements;
  }
  // extractAndHandleImages(htmlContent: string): void {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlContent, 'text/html');
  //   const imageLinks = doc.querySelectorAll('a img');
  //   imageLinks.forEach(img => {
  //     const parentLink = img.parentElement as HTMLAnchorElement;
  //     if (parentLink && parentLink.href) {
  //       this.renderer.listen(img, 'click', (event: MouseEvent) => {
  //         event.preventDefault();
  //         this.openModal(parentLink.href);
  //       });
  //     }
  //   });
  //   this.el.nativeElement.querySelector('#content').innerHTML = doc.body.innerHTML;
  // }

  openModal(videoLink: string) {
    const modal = document.getElementById('myModal') as HTMLDivElement | null;

    if (modal) {
      modal.style.display = "block";
      this.videoLink = videoLink;
      this.modalWindow.nativeElement.querySelector('.modal-body').innerHTML = this.insertIframe();

      if (typeof bootstrap !== 'undefined') {
      }
    } else {
      console.warn('Modal elements not found');
    }
  }

  insertIframe() {
    return '<iframe width="100%" height="315" src="' + this.videoLink + '" title="Vidéo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>';
  }

  // navigateToArticle(id: number): void {
  //   this.router.navigate(['/news-details', id]);
  // }

  slugify(str:string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
             .replace(/\s+/g, '-') // replace spaces with hyphens
             .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
  }
}
