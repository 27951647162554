import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { FooterComponent } from '../footer/footer.component';
import { GlobalService } from '../../legacy/providers/global.service';

@Component({
  selector: 'app-organigramme',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    PreFooterComponent,
    FooterComponent,
  ],
  templateUrl: './organigramme.component.html',
  styleUrl: './organigramme.component.css'
})
export class OrganigrammeComponent {
  constructor(private globalService: GlobalService) {}

  organirgammeData: any = [];
  organirgammeHeader: any;
  isLoading = false;


  ngOnInit() {
    this.loadOrganigrammeData();
    this.loadOrganigrammeHeader();
  }

  loadOrganigrammeHeader() {
    this.isLoading = true; 
    this.globalService.getOrganigrammeHeader().subscribe((response: any) => {
      console.log('organigrammeHeader', response);
      this.organirgammeHeader = response;
    },
    (error) => {
      console.error('Error loading organigramme header:', error);
    },
    () => {
      this.isLoading = false; 
    }
  );
  }

  loadOrganigrammeData() {
    this.isLoading = true; 
    this.globalService.getOrganigramme().subscribe(
      (response: any) => {
        if (response.success) {
          this.organirgammeData = response.data;
        }
        console.log('organigrammeData', response);
      },
      (error) => {
        console.error('Error loading organigramme data:', error);
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  }

