// import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { GlobalService } from './legacy/providers/global.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

// import { IndexMenuComponent } from './legacy/index-menu';
// import { NoContentComponent } from './components/no-content';


declare const $: any;

@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'lmv';


  public dir = 'ltr';

  constructor( private router: Router, public location: Location,
               private globalService: GlobalService) {

    // const routes = this.router;
    //
    // const result = Object.keys(routes).map(function(key) {
    //   return [key, routes[key]];
    // });
    // console.log( result);

    // for (let i = 0 ; i < routes.length; i++) {
    //   console.log('config routes : ' + routes[i]);
    //   // if (routes[i].config) {
    //   //   console.log('Childrens' + i + ' : ----------- ');
    //   //   for (let j = 0; j < routes[i].children; j++) {
    //   //     console.log(routes[i].children[j].path);
    //   //   }
    //   //   console.log('End childrens ------------');
    //   // }
    // }
  }

  public ngOnInit() {
    this.hideDarkMenu();
  }


  public svgIcons() {
    $('img.icon-svg').each(() => {
      const $img = $(this);
      const imgID = $img.attr('id');
      const imgClass = $img.attr('class');
      const imgURL = $img.attr('src');
      const imgWidth = $img.attr('width');
      const imgHeight = $img.attr('height');
      $.get(imgURL, function(data:any) {

        // Get the SVG tag, ignore the rest
        let $svg = $(data).find('svg');
        // Add replaced image's ID to the new SVG
        if (imgID !== undefined) {
          $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (imgClass !== undefined) {
          $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }
        if (imgWidth !== undefined) {
          $svg = $svg.attr('width', imgWidth);
        }
        if (imgHeight !== undefined) {
          $svg = $svg.attr('height', imgHeight);
        }
        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');
        // Replace image with new SVG
        $img.replaceWith($svg);
      }, 'xml');
    });
  }

  public onActivate() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  protected hideDarkMenu() {
    const arRoutes = [ '/particular/health-ar', '/particular/home-ar', '/health/dynamic-ar', '/particular/diagnostic-ar', '/search-result-ar', '/search-result/ar',
      '/particular/health/simulator-ar', '/particular/souscription-ar', '/particular/wikaya-ar', '/particular/result-wikaya-ar', '/particular/diagnostic/ar', '/Routes/ar' , '/ar' , '/Routes/dynamic/ar',
        , '/dynamic/ar' , '/souscription/ar' , '/particular/other/wikaya/ar', '/particular/other/souscription/ar', '/particular/other/simulator/ar' , '/particular/health/dynamic/ar', '/particular/health/ar',
      '/customer-space/login-ar', '/enterprise/ar', '/enterprise/ar/products' , '/enterprise/ar/description/:id' , '/enterprise/ar/detail/:id' , '/particular/prevoyance/product/wikaya/ar/result',
      '/customer-space/id-request-ar', '/particular/health/product-ar/1', '/accueil/particular/home/ar' , '/accueil/particular/diagnostic/ar' , '/particular/home/ar',
      '/customer-space/pass-received-ar', '/customer-space/recover-pass-ar', '/customer-space/grab-pass-ar', '/customer-space/edit-pass-ar',
      '/customer-space/my-contracts-ar', '/enterprise/description-ar', '/enterprise/complete-ar', '/customer-space/step-contract-ar'];
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.dir = (arRoutes.indexOf(this.location.path()) > -1) ? 'rtl' : 'ltr';
      }
    });
  }
}
