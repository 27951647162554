import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../../providers/global.service';

@Component({
  selector: 'site-map',
  templateUrl: './site-map.component.html',
  encapsulation: ViewEncapsulation.None,

})

export class SiteMapComponent implements OnInit {

  constructor(private globalService: GlobalService) {}

  public ngOnInit() {
    this.globalService.getSiteMapXml();
  }

}
