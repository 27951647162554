import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { DataService } from './data.service';
import { CommonModule } from '@angular/common';
import { Need } from '../../models/need';
import { isPlatformBrowser } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalService } from '../../legacy/providers/global.service';
import { SliderItem } from '../../models/sliderItem.model';
import { HelpSectionComponent } from '../../components/help-section/help-section.component';
import { PreFooterComponent } from '../../components/pre-footer/pre-footer.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { GridProductComponent } from '../../components/grid-product/grid-product.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Article } from '../../models/article.model';

declare var YT: any;
declare var bootstrap: any;

@Component({
  selector: 'app-main-content',
  standalone: true,
  imports: [
    CommonModule,
    HelpSectionComponent,
    PreFooterComponent,
    FooterComponent,
    HeaderComponent,
    GridProductComponent,
  ],
  templateUrl: './page-accueil.component.html',
  styleUrls: ['./page-accueil.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PageAccueilComponent implements OnInit {
  headerImage: string = '';
  headerTitle: string = '';
  headerButtonText: string = '';
  items: Need[] = [];
  sliderItems: SliderItem[] = [];
  isLoading = false;
  player: any;


  constructor(
    private dataService: DataService,
    private globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getNeedsData();
    this.getSliderData();
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  private getNeedsData() {
    this.isLoading = true;
    this.dataService.getNeedsByType('particulier').subscribe(
      (data: Need[]) => {
        this.items = data;
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('needs', JSON.stringify(data));
        }
        this.isLoading = false;
      },
      (error) => {
        console.error('There was an error!', error);
        this.isLoading = false;
      }
    );
  }

  private getSliderData() {
    this.isLoading = true;
    this.globalService.getSliderItems().subscribe(
      (data: SliderItem[]) => {
        this.sliderItems = data;
        console.log('Slider items:', this.sliderItems);
        this.isLoading = false;
      },
      (error) => {
        console.error('There was an error fetching slider data!', error);
        this.isLoading = false;
      }
    );
  }

  // navigateToForm() {
  //   this.router.navigate(['/product']);
  // }

  currentVideoUrl: string;

  videos: { url: string; thumbnail: string }[] = [];

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const videoModal = document.getElementById('videoModal');
      videoModal?.addEventListener('hidden.bs.modal', () => {
        this.stopVideo();
      });
    }
  }

  playVideo(videoUrls: { url: string }[] | string) {
    console.log('Play video triggered');
    let videoId: string | null = null;

    if (typeof videoUrls === 'string') {
      videoId = this.extractYouTubeId(videoUrls);
    } else if (Array.isArray(videoUrls) && videoUrls.length > 0) {
      videoId = this.extractYouTubeId(videoUrls[0].url);
    }

    if (videoId) {
      this.currentVideoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&enablejsapi=1`;
      console.log('Video ID:', videoId);
      console.log('Current video URL:', this.currentVideoUrl);

      if (isPlatformBrowser(this.platformId)) {
        const iframe = document.getElementById('modalVideo') as HTMLIFrameElement;
        if (iframe) {
          iframe.src = this.currentVideoUrl;
          console.log('Iframe source set to:', iframe.src);
        } else {
          console.error('Iframe element not found');
        }

        const videoModal = new bootstrap.Modal(
          document.getElementById('videoModal') as HTMLElement
        );
        videoModal.show();
        console.log('Modal shown');
      }
    } else {
      console.error('Could not extract video ID from URL:', videoUrls);
    }
  }

  extractYouTubeId(url: string): string | null {
    const regExp =
      /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=|youtube.com\/watch\?v=|youtube.com\/embed\/|youtube.com\/shorts\/)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  stopVideo(): void {
    const iframe: HTMLIFrameElement = document.getElementById('modalVideo') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = '';
    }
  }
}
