<app-header></app-header>
<!-- News Header -->
<div
  class="featured-image"
  style="background-image: url({{
    news.image
  }}); height: 400px;  background-position: center center;
background-size: cover;
background-repeat: no-repeat;"
></div>

<div class="content-news-wrapper" *ngIf="!isLoading">
  <div class="content-body">
    <div class="content-body-form">
      <div>
        <div class="content-news">
          <!-- Top image section -->
          <div class="news-header-tags news-tags">
            <p class="news-date">{{ news.date }}</p>
            <div class="featured-tags">
              <span class="" *ngFor="let tag of news.tags">{{ tag.name }}</span>
            </div>
            <h1 class="news-title">{{ news.title }}</h1>
          </div>

          <!-- News content section -->
          <div
            #newsContent
            class="news-body"
            [innerHTML]="bodyTransformed"
          ></div>

          <!-- Share section -->
          <div class="share-section">
            <p>Partagez l'article via</p>
            <div class="social-icons">
              <a
                [href]="
                  'https://www.linkedin.com/shareArticle?mini=true&url=' +
                  currentUrl
                "
                target="_blank"
                class="social-icon"
              >
                <img
                  src="/assets/images/LinkedIn.svg"
                  alt="LinkedIn"
                  loading="lazy"
                />
              </a>
              <a
                [href]="
                  'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl
                "
                target="_blank"
                class="social-icon"
              >
                <img
                  src="assets\images\Facebook.svg"
                  alt="Facebook"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="read-also-wrapper">
      <h2 class="read-also-h2">Lire aussi</h2>
      <div class="rm-item" *ngFor="let item of readAlso">
        <div
          class="rm-item-img"
          style="background-image: url({{ item.image }})"
        ></div>
        <div class="rm-item-cnt">
          <a
            [href]="['/actualites/' + slugify(item.title) + '/' + item.id]"
            class="featured-article-link"
          >
            <p>{{ item.date }}</p>
            <h3>{{ item.title }}</h3>
            <div class="read-also-tags">
              <span *ngFor="let tag of item.tags">{{ tag.name }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div
      #modal
      id="myModal"
      class="modal"
      tabindex="-1"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" aria-label="Close"></button>
          </div>
          <div class="modal-body modal-body-news"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-pre-footer></app-pre-footer>
<app-footer></app-footer>
