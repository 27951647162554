import { Component,ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { HelpSectionComponent } from '../help-section/help-section.component';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { FooterComponent } from '../footer/footer.component';
import { GlobalService } from '../../legacy/providers/global.service';
import { ObjetPopInComponent } from './objetPopIn/objet-pop-in.component';
import { Router } from '@angular/router';

import { scrollTo } from '../../helpers/scrollTo';
import { LmvLoader } from '../loader/loader.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HeaderComponent,
    HelpSectionComponent,
    PreFooterComponent,
    FooterComponent,
    ObjetPopInComponent,
    LmvLoader,
  ],
})
export class ContactComponent {
  form: FormGroup;
  selfIntrselectedOption: any | null = null;
  selfIntrdropdownOpen: boolean = false;
  contratTypeselectedOption: any | null = null;
  contratTypedropdownOpen: boolean = false;
  objectselectedOption: any | null = null;
  objectdropdownOpen: boolean = false;
  attachmentFileName: string | null = null;
  attachmentError: string | null = null;
  contactHeaderData: any;
  selfIntroductionTypes: any;
  contratTypes: any;
  contratSubjects: any;
  file: File | null = null;
  fileBase64: string | null = null;
  isLoading = false;
  selfIntroductionTouched: boolean = false;
  contratTypeTouched: boolean = false;
  objectTouched: boolean = false;
  errorsFound: boolean = false;
  submitOk: boolean = false;
  sendingForm: boolean = false;
  nContrat:string='';

  isObjetModalOpen = false;
  objetModalMessage =
    'Veuillez joindre votre demande envoyée à La Marocaine Vie';

  currentOpenDropdown: 'selfIntroduction' | 'contratType' | 'object' | null =
    null;

  showModal = false;

  @ViewChild('contactStart') contactStart!: ElementRef;

  openObjetModal() {
    this.isObjetModalOpen = true;
  }

  closeObjetModal() {
    this.isObjetModalOpen = false;
  }

  constructor(
    private fb: FormBuilder,
    private globalService: GlobalService,
    private router: Router
  ) {}

  writeAgain() {
    window.location.reload();
  }

  returnHome() {
    this.router.navigate(['/']);
  }

  ngOnInit() {
    // this.scrollToTop();
    this.initForm();
    this.loadContactHeaderData();
    this.loadselfIntroductionTypes();
    this.loadContratTypes();
    this.loadContratSubjects();
    this.loadContactPopupMsg();
  }

  // loadFormDatas ------------------------------------------------------------------------------------------------

  loadContactPopupMsg() {
    this.globalService.getContactPopupMsg().subscribe((response: any) => {
      if (response) {
        this.objetModalMessage = response;
      }
    });
  }

  loadContactHeaderData() {
    this.isLoading = true;
    this.globalService.getContactHeader().subscribe((response: any) => {
      this.isLoading = false;
      if (response) {
        this.contactHeaderData = response;
      }
    });
  }

  loadselfIntroductionTypes() {
    this.isLoading = true;
    this.globalService.getSelfIntroductionTypes().subscribe((response: any) => {
      this.isLoading = false;
      if (response) {
        this.selfIntroductionTypes = response;
      }
    });
  }

  loadContratTypes() {
    this.globalService.getContratTypes().subscribe((response: any) => {
      if (response) {
        this.contratTypes = response;
      }
    });
  }

  loadContratSubjects() {
    this.globalService.getContactSubjects().subscribe((response: any) => {
      if (response) {
        this.contratSubjects = response;
      }
    });
  }

  // initForm --------------------------------------------------------------------------------------------------

  initForm() {
    this.form = this.fb.group(
      {
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(20),
          ],
        ],
        lastName: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(20),
          ],
        ],
        phone: [
          '',
          [
            Validators.required,
            Validators.pattern('^[0-9]*$'),
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        selfIntroduction: ['', [Validators.required]],
        codeIntermédiaire: [''],
        cin: ['', [
          this.cinValidation,
          Validators.maxLength(10)]],
        nContrat: ['', [Validators.maxLength(15), Validators.minLength(4)]],
        contratType: ['', [Validators.required]],
        attachment: ['', [this.checkFileValidation]],
        object: ['', [Validators.required]],
        message: [
          '',
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(500),
          ],
        ],
      },
      { validator: this.atLeastOneRequired('cin', 'nContrat') }
    );
  }

  // cinValidation ----------------------------------------------------------------------------------------------
  cinValidation(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value && typeof value === 'string') {
    const hasNumber = /\d/.test(value);
    const hasCharacter = /[a-zA-Z]/.test(value);
    if (!hasNumber || !hasCharacter) {
      return { atLeastOneNumberAndOneCharacter: true };
    }
  }
  return null;
}

  // atLeastOneRequired ------------------------------------------------------------------------------------------

  atLeastOneRequired(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const control1 = formGroup.get(controlName1);
      const control2 = formGroup.get(controlName2);

      if (!control1 || !control2) {
        return null;
      }

      if (!control1.value && !control2.value) {
        return { atLeastOneRequired: true };
      }

      return null;
    };
  }

  // checkFileValidation ----------------------------------------------------------------------------------------

  checkFileValidation(control: AbstractControl): ValidationErrors | null {
    const file = control.value;
    if (file) {
      const allowedTypes = ['application/pdf', 'image/jpg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        return { invalidType: true };
      }
      if (file.size > 2 * 1024 * 1024) {
        return { invalidSize: true };
      }
    }
    return null;
  }

  // onFileSelected ---------------------------------------------------------------------------------------------

  async onFileSelected(event: any) {
    this.file = event.target.files[0];
    if (this.file) {
      const fileValidation = this.checkFileValidation({
        value: this.file,
      } as AbstractControl);
      if (fileValidation) {
        if (fileValidation['invalidType']) {
          this.attachmentError =
            'Le fichier doit être au format PDF, JPG ou PNG';
        } else if (fileValidation['invalidSize']) {
          this.attachmentError =
            'La taille du fichier ne doit pas dépasser 2 Mo.';
        }
        this.form.get('attachment')?.setErrors(fileValidation);
        this.form.get('attachment')?.setValue(null);
      } else {
        this.attachmentError = null;
        this.form.get('attachment')?.setErrors(null);
        this.form.get('attachment')?.setValue(this.file);
        this.fileBase64 = await this.convertToBase64(this.file);
      }
      this.form.get('attachment')?.markAsTouched();
      this.form.get('attachment')?.updateValueAndValidity();
    }
  }

  // toggleDropdown----------------------------------------------------------------------------------

  toggleDropdown(dropdown: 'selfIntroduction' | 'contratType' | 'object') {
    if (this.currentOpenDropdown === dropdown) {
      // If clicking on the currently open dropdown, close it
      this.currentOpenDropdown = null;
    } else {
      // Close the currently open dropdown (if any) and open the new one
      this.currentOpenDropdown = dropdown;
    }

    // Update the specific dropdown states
    this.selfIntrdropdownOpen = this.currentOpenDropdown === 'selfIntroduction';
    this.contratTypedropdownOpen = this.currentOpenDropdown === 'contratType';
    this.objectdropdownOpen = this.currentOpenDropdown === 'object';

    // Mark as touched if closing
    if (this.selfIntrdropdownOpen) {
      this.selfIntroductionTouched = true;
      this.form.get('selfIntroduction')?.markAsTouched();
      console.log('selfIntroductionTouched: 1');
    }
    if (this.contratTypedropdownOpen) {
      this.contratTypeTouched = true;
      this.form.get('contratType')?.markAsTouched();

      console.log('contratTypeTouched: 2');
    }
    if (this.objectdropdownOpen) {
      this.objectTouched = true;
      this.form.get('object')?.markAsTouched();
      console.log('objectTouched: 3');
    }
  }

  // selectContratTypeOption ----------------------------------------------------------------------------------

  selectContratTypeOption(option: any) {
    this.contratTypeselectedOption = option;
    this.form.controls['contratType'].setValue(option.tid);
    this.toggleDropdown('contratType');
  }

  // selectSelfIntroductionOption -----------------------------------------------------------------------------

  checkSelfIntroductionOption(option: any) {
    const codeIntermédiaire = this.form.get('codeIntermédiaire');

    if (option.tid == 111) {
      codeIntermédiaire?.setValidators([
        Validators.required,
        Validators.minLength(4),
      ]);
    } else {
      codeIntermédiaire?.clearValidators();
    }

    const cin = this.form.get('cin');
    const nContrat = this.form.get('nContrat');
    const contratType = this.form.get('contratType');
    const message = this.form.get('message');
    const object = this.form.get('object');

    if (option.tid == 125) {
      cin?.clearValidators();
      nContrat?.clearValidators();
      contratType?.clearValidators();
      message?.removeValidators(Validators.required);
      object?.clearValidators();

      this.form.clearValidators();
    } else {
      cin?.setValidators([Validators.minLength(5), Validators.maxLength(8)]);
      nContrat?.setValidators([
        Validators.maxLength(15),
        Validators.minLength(4),
      ]);
      contratType?.addValidators(Validators.required);
      message?.addValidators(Validators.required);
      this.form.setValidators(
        this.atLeastOneRequired('cin', 'nContrat') as any
      );
      object?.addValidators(Validators.required);
    }

    codeIntermédiaire?.updateValueAndValidity();
    cin?.updateValueAndValidity();
    nContrat?.updateValueAndValidity();
    contratType?.updateValueAndValidity();
    message?.updateValueAndValidity();
    object?.updateValueAndValidity();
    this.form.updateValueAndValidity();
  }

  selectSelfIntroductionOption(option: any) {
    // const Attachment = this.form.get('attachment');
    // if (option.tid == 125) {
    //   Attachment?.clearValidators();
    //   Attachment?.updateValueAndValidity();
    // } else {
    //   Attachment?.setValidators(Validators.required as any);
    //   Attachment?.updateValueAndValidity();
    // }

    this.selfIntrselectedOption = option;
    this.form.controls['selfIntroduction'].setValue(option.tid);
    this.toggleDropdown('selfIntroduction');
    this.checkSelfIntroductionOption(option);
  }

  // selectObjectOption ---------------------------------------------------------------------------------------

  selectObjectOption(option: any) {
    this.objectselectedOption = option;
    this.form.controls['object'].setValue(option.tid);
    this.toggleDropdown('object');

    if (option.tid == 123) {
      this.openObjetModal();
    }
  }

  // convertToBase64 --------------------------------------------------------------------------------------------

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  // onSubmit --------------------------------------------------------------------------------------------------

  getSubmitPayload() {
    const Payload = {
      firstName: this.form.value.name,
      lastName: this.form.value.lastName,
      phone: this.form.value.phone,
      email: this.form.value.email,
      type: this.form.value.selfIntroduction,
      cin: this.form.value.cin,
      numContrat: this.form.value.nContrat,
      typeContrat: this.form.value.contratType,
      subject: this.form.value.object,
      message: this.form.value.message,
      pj: '',
      codeIntermediaire: this.form.value.codeIntermédiaire,
    };

    if (this.fileBase64) {
      Payload['pj'] = this.fileBase64;
    }

    return Payload;
  }

  resetAfterSubmit() {
    this.form.reset();
    this.attachmentFileName = null;
    this.attachmentError = null;
    this.file = null;
    this.fileBase64 = null;
    this.selfIntrselectedOption = null;
    this.contratTypeselectedOption = null;
    this.objectselectedOption = null;
  }

  async onSubmit() {
    if (this.form.valid) {
      const body = this.getSubmitPayload();
      this.sendingForm = true;

      this.globalService.sendContactForm(body).subscribe((response: any) => {
        this.sendingForm = false;
        if (response.success) {
          // this.resetAfterSubmit();
          this.showModal = true;
          this.submitOk = true;
          this.errorsFound = false;
          scrollTo('status-messages');
          console.log('Form sent successfuly');
        } else {
          this.submitOk = false;
          this.errorsFound = true;
          scrollTo('status-messages');
          console.log('Error when sending form');
        }
      });
    } else {
      scrollTo('status-messages');
    }
    // this.scrollToTop();
    if (this.form.valid) {
      this.router.navigate(['/contact']);
    }
  }

  scrollToTop() {
    // this.contactStart.nativeElement.scrollIntoView({ behavior: 'smooth' });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  // scrollToTop() {
  //   // this.contactStart.nativeElement.scrollIntoView({ behavior: 'smooth' });
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //    });
  // }
}
