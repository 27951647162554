import { Component, ViewEncapsulation,HostListener } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { FooterComponent } from '../footer/footer.component';
import { CommonModule } from '@angular/common';
import { GlobalService } from '../../legacy/providers/global.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { scrollTo } from '../../helpers/scrollTo';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'app-nous-connaitre',
  standalone: true,
  imports: [HeaderComponent, PreFooterComponent, FooterComponent, CommonModule, NgScrollbarModule],
  templateUrl: './nous-connaitre.component.html',
  styleUrl: './nous-connaitre.component.css',
  encapsulation: ViewEncapsulation.None
})
export class NousConnaitreComponent {
  constructor(
    private globalService: GlobalService,
    private sanitizer: DomSanitizer
  ) { }

  videoUrl: SafeResourceUrl;
  videoLoaded: boolean = false;
  imageUrl: SafeResourceUrl;
  globalAboutData: any = [];
  globalHeaderData: any = [];
  valeursData: any = [];
  showValeurs: boolean = true;
  keyFiguresData: any = [];
  fields = [
    'field_chiffre_1',
    'field_chiffre_2',
    'field_chiffre_3',
    'field_chiffre_4',
    'field_chiffre_5',
    'field_chiffre_6',
  ];
  // isLoading = true;
  loadGAD: boolean = true;
  loadAWWA: boolean = true;
  loadAKF: boolean = true;
  scrolledTo: boolean = false;
  isMobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= 768;
  }
  ngOnInit() {
    this.loadGlobalAboutData();
    this.loadAboutWhoAreWeHeader();
    this.loadAboutKeyFigures();
    this.isMobile = window.innerWidth <= 768;
  }

  loadAboutKeyFigures() {
    this.globalService.getAboutKeyFigures().subscribe((data) => {
      console.log('getAboutKeyFigures: ', data);
      this.keyFiguresData = data;
      this.loadAKF = false;
    });
  }

  loadAboutWhoAreWeHeader() {
    this.globalService.getAboutWhoAreWeHeader().subscribe((data) => {
      this.globalHeaderData = data;
      this.loadAWWA = false;
    });
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  convertToEmbedUrl(url: string): string {
    if (!url) return '';
    return url.replace('watch?v=', 'embed/');
  }

  loadGlobalAboutData() {
    this.globalService.getAboutData().subscribe((data) => {
      success: {
        console.log('getGlobalAboutData: ', data);
        this.globalAboutData = data;
        this.extractValeursData(data[0]);
        this.globalAboutData[0].content = this.sanitizer.bypassSecurityTrustHtml(this.globalAboutData[0]?.content);
        // Masquer la section nos valeurs.
        this.showValeurs = false;
        const videourl = this.convertToEmbedUrl(this.globalAboutData[0]?.video);
        if (videourl == undefined || videourl == null || videourl == '') {
          this.imageUrl = this.globalAboutData[0]?.img_url;
        } else {
          this.videoLoaded = true;
          this.videoUrl = this.sanitizeUrl(videourl);
        }
        this.loadGAD = false;
      }
    },
      (error) => {
        console.error('Error loading global about data:', error);
      }
    );
  }

  extractValeursData(data: { items: any[][] }): void {
    this.valeursData = [...this.valeursData, ...data.items.flat()];
  }

  isLoading(): boolean {
    return !this.loadAKF && !this.loadAWWA && !this.loadGAD;
  }

  ngAfterViewChecked() {
    // console.log("=== ngAfterViewChecked(2)")
    const gotoId = window.location.hash?.substring(1);
    const sectionExists = document.getElementById(gotoId);
    if (gotoId != "" && this.scrolledTo === false && sectionExists != null) {
      // console.log("Scroll is possible");
      this.scrolledTo = true;
      scrollTo(gotoId);
    }
  }
}
