import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { HelpSectionComponent } from '../help-section/help-section.component';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { FooterComponent } from '../footer/footer.component';
import { GlobalService } from '../../legacy/providers/global.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

interface ConvertedDataItem {
  title: string;
  icon: string;
  description: string;
}

@Component({
  selector: 'app-nous-rejoindre',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    HelpSectionComponent,
    PreFooterComponent,
    FooterComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './nous-rejoindre.component.html',
  styleUrl: './nous-rejoindre.component.css',
})
export class NousRejoindreComponent {
  constructor(private fb: FormBuilder, private globalService: GlobalService) {}

  form: FormGroup;
  file: File | null = null;
  fileBase64: string | null = null;
  attachmentFileName: string | null = null;
  attachmentError: string | null = null;
  globalHeaderData: any = [];
  CondidatureBlockData: any = [];
  subBodyData: ConvertedDataItem[] = [];
  isLoading = false;

  ngOnInit() {
    this.initForm();
    this.loadNousRejoindreHeader();
    this.loadNousRejoindreCondidatureData();
    this.loadNousRejoindreSubBodyData();
  }

  loadNousRejoindreHeader() {
    this.isLoading = true; 
    this.globalService.getNousRejoindreHeader().subscribe((data) => {
      this.isLoading= false; 
      this.globalHeaderData = data;
    });
  }

  loadNousRejoindreCondidatureData() {
    this.isLoading= true; 
    this.globalService.getNousRejoindreCondidature().subscribe((data) => {
      this.isLoading= false; 
      this.CondidatureBlockData = data;
      // console.log('getNousRejoindreCondidatureData: ', data);
    });
  }

  loadNousRejoindreSubBodyData() {
    this.isLoading= true; 
    this.globalService.getNousRejoindreSubBody().subscribe((data) => {
      this.isLoading= false; 
      this.subBodyData = this.convertData(data[0]);
      // console.log('getNousRejoindreSubBodyData: ', data);
    });
  }

  convertData(originalData: any) {
    const convertedData: ConvertedDataItem[] = [];

    for (let i = 1; i <= 3; i++) {
      const titleField = `field_titre${i === 1 ? '' : '_' + i}`;
      const imageField = `field_image${i === 1 ? '' : '_' + i}`;
      const descriptionField = `field_description_${i}`;

      const title = originalData[titleField] || '';
      const icon = originalData[imageField]?.uri || '';
      const description = (originalData[descriptionField] || '').replace(
        /<\/?p>|<\/?span>/g,
        ''
      );

      convertedData.push({ title, icon, description });
    }

    return convertedData;
  }

  initForm() {
    this.form = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      attachment: ['', [Validators.required, this.checkFileValidation]],
      message: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(500),
        ],
      ],
      checkCondition: ['', [Validators.required, this.validateCondition]],
    });
  }

  // validateCondition ---------------------------------------------------------------------------------------------
  validateCondition(control: AbstractControl) {
    if (control.value !== '') {
      if (control.value === false) {
        return { validCondition: true };
      }
    }

    return null;
  }

  // checkFileValidation ---------------------------------------------------------------------------------------------

  // if (!control.value.endsWith('.pdf') && !control.value.endsWith('.jpg') &&
  //       !control.value.endsWith('.jpeg') && !control.value.endsWith('.png') &&
  //       !control.value.endsWith('.docx') && !control.value.endsWith('.zip') &&
  //       !control.value.endsWith('.rar') ) {
  checkFileValidation(control: AbstractControl): ValidationErrors | null {
    const file = control.value;
    if (file) {
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'application/zip',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      ];
      if (!allowedTypes.includes(file.type)) {
        return { invalidType: true };
      }
      if (file.size > 2 * 1024 * 1024) {
        return { invalidSize: true };
      }
    }
    return null;
  }

  // onFileSelected ---------------------------------------------------------------------------------------------

  async onFileSelected(event: any) {
    this.file = event.target.files[0];
    if (this.file) {
      const fileValidation = this.checkFileValidation({
        value: this.file,
      } as AbstractControl);
      if (fileValidation) {
        if (fileValidation['invalidType']) {
          this.attachmentError =
            'Le fichier doit être au format PDF, JPEG, PNG, ZIP ou Word (DOCX).';
        } else if (fileValidation['invalidSize']) {
          this.attachmentError =
            'La taille du fichier ne doit pas dépasser 2 Mo.';
        }
        this.form.get('attachment')?.setErrors(fileValidation);
        this.form.get('attachment')?.setValue(null);
      } else {
        this.attachmentError = null;
        this.form.get('attachment')?.setErrors(null);
        this.form.get('attachment')?.setValue(this.file);
        this.fileBase64 = await this.convertToBase64(this.file);
      }
      this.form.get('attachment')?.markAsTouched();
      this.form.get('attachment')?.updateValueAndValidity();
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  // onEmailClick ---------------------------------------------------------------------------------------------

  onEmailClick(email: string) {
    window.location.href = `mailto:${email ? email : ''}`;
  }


  // onSubmit --------------------------------------------------------------------------------------------------

  getSubmitPayload() {
    const Payload = {
      firstName: this.form.value.name,
      lastName: this.form.value.lastName,
      phone: this.form.value.phone,
      email: this.form.value.email,
      message: this.form.value.message,
      pj: this.fileBase64,
    };

    // if (this.fileBase64) {
    //   Payload['file'] = this.fileBase64;
    // }

    return Payload;
  }

  resetAfterSubmit() {
    this.form.reset();
    this.attachmentFileName = null;
    this.attachmentError = null;
    this.file = null;
    this.fileBase64 = null;
  }

  async onSubmit() {
    if (this.form.valid) {
      const body = this.getSubmitPayload();
      this.globalService.sendJoinUs(body).subscribe((response: any) => {
        if (response.success) {
          this.resetAfterSubmit();
          alert('Votre Formulaire a été envoyé avec succès');
        } else {
          alert("Une erreur s'est produite, veuillez réessayer");
        }
      });
    }
  }
}
