import { Component, HostListener, Renderer2, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import {FooterComponent} from '../footer/footer.component';
import {PreFooterComponent} from '../pre-footer/pre-footer.component';
import {HelpSectionComponent} from '../help-section/help-section.component';
import { CommonModule } from '@angular/common';
import { GlobalService } from '../../legacy/providers/global.service';


@Component({
  selector: 'app-legal-notice',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    PreFooterComponent,
    HelpSectionComponent,
    CommonModule
  ],
  templateUrl: './legal-notice.component.html',
  styleUrl: './legal-notice.component.css',
  encapsulation: ViewEncapsulation.None
})
export class LegalNoticeComponent implements OnInit {
  constructor(private globalService: GlobalService, private renderer: Renderer2) {}

  legalsData: any = [];
  legalNoticeHeader: any;
  openCards: boolean[] = [];
  isMobileView: boolean = false;
  isLoading = false;


  ngOnInit() {
    let path = window.location.pathname.substring(1);
    console.log("Path = " + path);
    if (path == 'mention') {
      path = 'legals';
    }

    this.loadLegalNoticeHeader(path);
    this.loadLegalsData(path);
  }

  loadLegalNoticeHeader (path:string) {
    this.isLoading = true;
    this.globalService.getLegalNoticeHeader(path).subscribe((response: any) => {
      this.isLoading = false;
      this.legalNoticeHeader = response;
      console.log('legalNoticeHeader: ', response);
    }
    );
  }

  loadLegalsData(path:string) {
    this.isLoading = true;
    this.globalService.getLegals(path).subscribe((response: any) => {
      this.isLoading = false;
      this.legalsData = response;
    });
    this.openCards = this.legalsData?.map(() => false);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkViewMode();
  }

  checkViewMode() {
    this.isMobileView = window.innerWidth <= 768;
  }

  toggleDropCard(index: number): void {
    if (this.isMobileView) {
      this.openCards[index] = !this.openCards[index];
    }
  }

}
